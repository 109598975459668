.footerbar {
  width : 100%;
  height : 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
  display: flex;
  align-items : center;
  justify-content: space-evenly;
}
.footerNavBar .footerLinks {
  display : flex;
  gap : 8rem;
}
.footerLinks {
  margin: auto;
}

.footerLi {
  display : inline;
  margin : 3vw;
  height : 100%;
  vertical-align: middle;
}

.back-to-top {
  width: 70px;
  height: 70px;
  background-color: #2C3862;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 1;
  margin: auto;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  margin-bottom: 15px;
}

.back-to-top.show {
  opacity: 1;
  visibility: visible;
}

.arrow {
  width: 20px;
  height: 20px;
  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
  transform: rotate(-45deg);
}
.backToTopText {
  font-size: 25px;
  font-family: "Inter";
  font-weight:400;
  font-style: normal;
}

.footerWrapper {
  height : 50vh;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  padding-top: 20vh;
  
}
.icons:hover {
  cursor: pointer;
}

@media(max-width : 576px) {

  .footerBarWrapper {
    display: none;
  }
  .backToTopText {
    
    font-size: 16px;
  }
  .back-to-top {
    width : 45px;
    height: 45px;
  }
  .arrow {
   
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    transform: rotate(-45deg);
  }
}