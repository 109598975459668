.mainMenu {
    height : 400px;
    background-color: black;
    background-image : url('../Images/menu.jpg');
    background-size :cover;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
}
.menuTitle {
    width : 100%;
    text-align : center;
    font-size : 65px;
    position: relative;
    top: 200px;
    color : white;
    font-weight: 500;
}

@media(max-width : 576px) {
    .mainMenu {
        height : 28vh;
    }
    .menuTitle {
        font-size : 25px;
        top: 0px;
    }
}