.haapyHourContainer {
    background-color : white;
    width :100%; 
    height : 100%;
    margin-top : 60px;
}


@media(max-width : 576px) {
    .haapyHourContainer {
        background-color : white;
        width :100%; 
        height : 100%;
        padding : 25px;
        margin-top : 50px;
        font-size : 13px;
    }
    
    
}