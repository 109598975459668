.nav-tabs {
  height : 60px;
}
.nav-item {
  height : 100%;
}
.nav-link {
  height :100%;
  color:black;
  font-size:19px;
}

.nav-link.active {
  font-weight: bold;
  font-size:20px;
}

.nav-link:hover {
  font-weight: bold;
  font-size:20px;
  color : black
}

.marginBtm {
  margin-bottom: 5vh;
}

@media(max-width : 576px) {
  .customTab {
      font-size : 13px;
      margin : 25px;
  }
  .nav-link {
    font-size:15px;
  }
  .nav-link.active {
    font-weight: bold;
    font-size:15px;
  }
  .nav-link:hover {
    font-weight: bold;
    font-size:15px;
    color : black
  }
}