
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
  font-family: "Inter";
}
.atag {
  text-decoration: none;
  color: white;
  font-size: 1rem;
}
.atag:hover {
  color : #5FCCDB;
  cursor : pointer;
}

a:hover {
  color : #5FCCDB;
}

header {
  position : fixed;
  top:0;
  padding : 0 2rem;
  width: 100%;
}
.navbar {
  width : 100%;
  height : 100px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items : center;
  justify-content: space-evenly;
  background-color: transparent;
}
.navbar-scrolled {
  width : 100%;
  height : 80px;
  /* max-width: 1200px; */
  margin: 0 auto;
  /* display: flex; */
  align-items : center;
  background-color: black;
  position: fixed;
  justify-content: space-between;
  /* margin-left: 30px; */
  /* right: 1000; */
  z-index: 9999;
  transition: background-color 1s ease-in-out;
  background-color: black;
}

.navbar .logo a {
  font-size: 1.5rem;
  font-weight : bold;
}

.navbar .links {
  display : flex;
  gap : 8rem;
}

.navbar .toggle_btn {
  color : white;
  font-size : 1.5rem;
  cursor : pointer;
  display: none;
}


.dropdown_menu {
  display: none;
  position : absolute;
  right : 2rem;
  top:60px;
  height : 0px;
  width : 300px;
  background : rgba(180,180,180, 1);
  z-index: 9999;  
  
  overflow: hidden;
  transition : height 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.dropdown_menu.open {
  height : 300px;  z-index: 9999;  
  
}
.dropdown_menu li {
  padding : 0.7rem;
  display : flex;
  align-items: center;
  justify-content: center;
}
.dropdown_menu {
  width : 100%;
  display: flex;
  justify-content: center;
}
@media(max-width : 992px) {
  .navbar .links,
  .navbar .action_btn {
    display: none;
  }
  .navbar .toggle_btn {
    display: block;
  }
  .dropdown_menu {
    display: block;
    
  }
}

@media(max-width : 576px) {
  .navbar {
    justify-content: right;
  }
  .dropdown_menu {
    left : 2rem;
    width : unset;
  }

  .dropdown_menu.open {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;   
    z-index: 9999;
  }
  a {
    color: black;
    font-size: 1.5rem;
    font-weight:bold;
    font-family: "Inter";
  }
  .xmarkWrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    text-align: right;
    margin-top : 2rem;
    font-weight: bold;
    font-size: 25px; 
  }
  .mobileLiWrapper {
    margin:30px;
  }
  header {
    position : relative;
    
  }
}