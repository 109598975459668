  .main_au {
    height : 500px;
    background-image : url('../Images/menu_mobile.jpg');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 35vh;
  }
  .aboutus1 {
    background-color: rgba(0,0,0,0.1);
    background-blend-mode: lighten;
    width: 100%;
    margin-top : 40vh;
    height : 700px;
  }
  .aboutus2 {
    background-image : url('../Images/aboutus2.jpg');
    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0.1);
    background-blend-mode: lighten;
    width: 100%;
    height : 700px;
    margin-top : 40vh;
  }
  .aboutus3 {
    background-color: rgba(0,0,0,0.1);
    width: 100%;
    height : 700px;
    margin-top : 40vh;
  }
  .contentsWrapper_au {
    margin-top: 0px;
    
  }
  @media(max-width : 576px) {
    .main_au {
        background-image : url('../Images/menu_mobile.jpg');
        height : 28vh;
        margin-bottom: 15vh;
      } 
      .aboutus1 {
        margin-top : 10vh;
      }
      .aboutus2 {
        background-image : none;
        background-color: rgba(0,0,0,0.1);
      }
      .aboutus3 {
        margin-top : 10vh;
        height : 500px;
      }
      .contentsWrapper_au {
        margin-bottom: 0px;
        
      }
}
 