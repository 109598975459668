html {
  overflow-x: hidden;
  /* background-color: black; */
}
* {
  margin : 0;
  padding : 0;
  box-sizing: border-box;
  font-family: "Poppins";
}
body {
  overflow-x: hidden;
  width : auto!;
  /* background-color: black; */
  padding-right: 0px !important;
  
}
body.modal-open {
  overflow: hidden !important;
}
.wrap {
  background-color: black;
}
.main {
  height : 110vh;
  background-image : url('./Images/banner2.png');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 35vh;
}

.happyhour {
  background-image : url('./Images/taco.jpg');
  background-size: 100% 80%;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0.1);
  background-blend-mode: lighten;
  width: 100%;
  height : 700px;
}

.aboutus {
  background-image : url('./Images/cocktail.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top : 40vh;
  width: 100%;
  height: 700px;
}

.instagram {
  margin-top : 40vh;
  width: 100%;
  height : 100%;
}


.contactus {
  margin-top : 40vh;
  width: 100%;
  height : 100%;

}

.container{
  height : 100%;
  background-color : black;
  padding: 0px;
  
}
.banner {
  color  : white;
  /* margin-top: 20px; */
  font-size: 65px;
  font-weight: 600;
}
.bannerWrapper {
  text-align : center;
  width : 100%; 
  vertical-align : center; 
  position: relative;
  top: 400px;
}

.mainTitle {
  color : white;
  margin-top : 15px;
  font-size : 32px;
  margin-top : 0px;
  margin-Right : 10px;
  margin-bottom: 50px;
  font-style: italic;
  font-weight: 400;
}
.subtitle {
  color  : white;
  margin-top: 20px;
  font-size: 48.394px;
  font-weight: 600;

}
.mainBtn {
  border-radius: 0;
  font-weight: 600;
  font-size: 25px;
  margin-top:10px;
  margin-bottom: 10px;
  font-style: normal;
  font-family : "Inter";

}
.smBtn {
  width : 15%;
}
.happyHourBtn {
  width : 70%;
}
.contents {
  color : white;
  margin-top : 15px;
  font-size : 21px;
  margin-Right : 0px;
  margin-bottom: 20px;
  font-weight: 400;
  text-align: justify;
  font-style : normal;
}
.happyhourContents {
  width : 80%;
  margin-top : 40px;
  margin-bottom : 40px;
}
.aboutusContents {
  text-align :justify;
}

.aboutUsWrapper {
  height:70vh;
}

.rectangle {
  height: 11px;
  width: 70px;
  background-color: #5FCCDB;

}

.contentsWrapper {
  margin-top: 10vh;
  margin-bottom:20vh;
}
.aboutusContentsWrapper {
  margin-top: 5vh;
  margin-right : 5vw;
}
.instaRectangle {
  margin : auto;
}
.instaContents {
  margin-bottom : 80px;
}
.instaBtn {
  width : 30%;
  margin-top : 50px;
  text-align: center;
}
.contactusContents {
  margin-bottom : 80px;
  font-family: "Inter";
}
.contactContents {
  padding : 10px;
  text-align: left;
  font-family : "Inter";
  font-style : normal;
  font-weight: 400;
  font-size : 25px;
  color: white;
}
.contactUsInfoWrapper {
  display : flex; 
  flex-direction : column;
  justify-content: space-between;
}
.contactUsInfoTable {
  color : white;
  margin : auto 0;
  display : table;
  flex-basis :25%;
}
.contactUsInfoText {
  display : table-cell;
  vertical-align : middle
}
.contactUsIcon {
  margin: auto;
  font-size: 20px;
}
.contacUsInfoRow {
  height: 100%
}
.contactUsMap {
  width : 100%;
}

@media (min-width: 992px) {
  .custom-col {
    flex: 0 0 20%; 
    max-width: 20%;
  }
}

@media(max-width : 576px) {
  .banner {
    font-size: 30px;
  }
  .mainTitle {
    font-size : 20px;
    margin-bottom : 10px;
  }
  .subtitle {
    font-size : 30px;
  }
  .mainBtn {
    font-size : 15px;
  }
  .smBtn {
    width: 30%;
  }
  .main {
    background-image : url('./Images/banner_mobile.jpg');
    background-size: 100% 90%;
    background-position-y: 0%;
    height : 100vh;
    margin-bottom : 5vh;

  }
  .bannerWrapper { 
    z-index: 9000;
    top: 200px;
  }

  .happyhour {
    background-image : url('./Images/taco_mobile.jpg');
    height : 40vh;
    background-size : 100% 100%;
    background-position-x: center;
    background-repeat: no-repeat;
    margin-top : 0vh;
    
  }
  .aboutus {
    background-image : url('./Images/cocktail_mobile.jpg');
    background-size : 100% 100%;
    height : 100%;
    width:100%;
    margin-top : 13vh;
  }
  .subtitle {
    font-size: 25px;
    text-align: center;
    font-weight: semi-bold;
  }
  .contents {
    font-size : 16px;
    padding:15px;
    text-align: center;
    font-style : normal;
    font-weight: 400;
    text-align: justify;
  }
  
  .happyhourContents {
    margin : auto;
    padding-left : 0px;
    padding-right : 0px;  
    text-align: justify;
    margin-bottom : 0px;
  }
  .happyhourWrapper {
    height : 100%
  }
  
  .aboutUsWrapper {
    width: 100%;
    height: 100%;
  }
  .happyHourBtn {
    width : 60%;
  }
  .rectangle {
    margin:auto;
  }
  .contentsWrapper {
    text-align: center;
    margin-top:3vh;

  }
  .contactusContents {
    margin-bottom : 0px;
    margin-top:0px;
    font-family: "Inter";
  }

  .aboutusContents {
    padding : 5px;
    text-align: justify;
    font-family: "Inter";
    font-weight : 400;
    font-style : normal;
  }
  .instagram {
    margin-top : 15vh;
  }
  .instaPics {
    width : "100%";
    height : 80px;
  }
  .instaBtn {
    width : 50%;
    text-align: center;
    margin-top : 0px;
  }
  .instaContents {
    margin-bottom : 10px;
  }
  .contactus {
    margin-top : 25vh;
  }
  .contactContents {
    padding : 0px;
    text-align: left;
    display: flex;
    flex-direction : row;
  }
  .aboutusContentsWrapper {
    
    padding:2vh;
    text-align: center;
    margin: 0 auto;
  }
  .contacUsInfoRow {
    height: 550px;
  }
  .contactUsMap {
    width : 90%;
  }
}