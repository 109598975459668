.generalMenuTitle {
    font-size:40px;
    font-weight : bold;
}
.singleMenuTitle {
    font-size:20px
}

.mgTop60 {
    margin-top : 90px;
}
.subMenuTitle {
    font-size:20px;
    font-weight : bold;
    align-self: flex-end; 
    padding-bottom: 10px;
}

@media(max-width : 576px) {
    .generalMenuTitle {
        font-size:20px
    }
    .singleMenuTitle {
        font-size:15px
    }
    .mgTop60 {
        margin-top : 60px;
    }
    .subMenuTitle {
        font-size:14px;
        font-weight : normal;
        align-self: flex-start; 
        padding-bottom: 0px;
    }
    .comment {
        font-size:14px;
        color:#676767
    }
}